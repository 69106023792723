import React, { useEffect } from "react";

import { Typography, Grid, Card, Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import CreateIcon from "@mui/icons-material/Create";

import ProjectDesignInvoicesTable from "../ProjectDesignInvoicesTable";
import DesignCostsModal from "../DesignCostsModal";
import { openDialog } from "../../../reusable/ScrollablePopup";
import { labelColour, poundUK } from "../../../queries/Project";
import ProjectDates from "./ProjectDates";
import { primaryColor } from "../../../assets/ThemeStyling";
import ProjectOverview from "./ProjectOverview";
import { designProjectStatuses } from "../../../queries/Project";
export default function ProjectDesign({ project, updateProject }) {
  useEffect(() => {}, []);

  return (
    <>
      <ProjectOverview
        service={"Design"}
        project={project}
        updateProject={updateProject}
        property={"status_design"}
        statuses={designProjectStatuses}
      />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "30%", marginRight: "24px" }}>
            <Card style={{ marginBottom: "24px", padding: "0" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "18px 24px",
                    // borderBottom: "1px solid #E7EAEF",
                    backgroundColor: "#4B4F54",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      color: "white",
                    }}
                  >
                    Design Costs
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        onClick={() => {
                          openDialog({
                            content: (
                              <DesignCostsModal
                                project={project}
                                onSaveClicked={(newProject) => {
                                  updateProject({
                                    variables: {
                                      ...newProject,
                                      _id: project._id,
                                    },
                                  });
                                }}
                              />
                            ),
                          });
                        }}
                      >
                        <CreateIcon style={{ fill: primaryColor }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>

                <div style={{ padding: "1em", width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <Typography
                            variant="body2"
                            style={{ color: labelColour }}
                            gutterBottom
                          >
                            Status
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ paddingBottom: "0.5em" }}>
                          <Typography variant="body1">Design Fees</Typography>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {poundUK.format(
                              project.design_budget ? project.design_budget : 0,
                            )}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            variant="body2"
                            style={{ color: labelColour }}
                            gutterBottom
                          >
                            F&amp;F Costs
                          </Typography>
                        </td>
                      </tr>
                      {project.ffEntries.map((entry) => {
                        return (
                          <tr>
                            <td style={{ paddingBottom: "0.5em" }}>
                              <Typography variant="body1">
                                {entry.name}
                              </Typography>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <Typography variant="body1">
                                {poundUK.format(entry.value ? entry.value : 0)}
                              </Typography>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td style={{ paddingBottom: "0.5em" }}>
                          <Typography variant="body1">F&amp;F Total</Typography>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <Typography variant="body1">
                            {poundUK.format(
                              project.ff_budget ? project.ff_budget : 0,
                            )}
                          </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Card>
          </div>
          <div style={{ width: "80%" }}>
            <Card style={{ padding: "0" }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    padding: "26px 24px",
                    // borderBottom: "1px solid #E7EAEF",
                    backgroundColor: "#4B4F54",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      color: "white",
                    }}
                  >
                    Invoices Raised
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ProjectDesignInvoicesTable
                    invoices={project.eque2Invoices}
                  />
                </Grid>
              </Grid>
            </Card>
          </div>
        </Grid>

        <Grid item xs={12}>
          <ProjectDates
            project_id={project.project_id}
            department=""
            title="Project Dates"
          />
        </Grid>
      </Grid>
    </>
  );
}
