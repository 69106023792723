import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import gql from "graphql-tag";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  createTheme,
} from "@material-ui/core";
import { openSnackbar } from "../../reusable/Notifier";
import { MoreVert } from "@material-ui/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  AwaitingDeposit,
  Active,
  Complete,
  Cancelled,
} from "../../assets/images/Icons";
import { format, isAfter, isBefore, parse } from "date-fns";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 140,
        },
        "&:nth-child(2)": {
          width: 160,
        },
        "&:nth-child(4)": {
          width: 140,
        },
      },
    },
  },
});

let poundUK = Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

// const theme = createMuiTheme({
//   overrides: {
//     MuiTable: {
//       root: {
//         border: [[0, 'solid', '#d3d3d3']],
//       },
//     },
//   },
// });

export default function ProjectDatesTable({ invoices }) {
  const tableDataArray = [];
  if (invoices != null) {
    invoices.forEach((invoice) => {
      const data = [
        invoice.name,
        invoice.dateCreated,
        invoice.title,
        invoice.value,
      ];
      tableDataArray.push(data);
    });
  }

  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: "Date Issued",
      direction: "desc",
    },
  };

  const columnsArray = [
    {
      name: "Invoice No.",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "Date Issued",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD/MM/YYYY");
        },
        display: true,
        sort: true,
      },
    },
    {
      name: "Title",
    },
    {
      name: "Value",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return poundUK.format(value);
        },
        sort: true,
        display: "true",
      },
    },
  ];

  return (
    <ThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        data={tableDataArray}
        columns={columnsArray}
        options={options}
      />
    </ThemeProvider>
  );
}
