import React, { useContext } from "react";
import { UserContext } from "../../utils/PageWrapper";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton, createTheme } from "@material-ui/core";
import { RemoveRedEye } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import { poundUK, useHistoryState } from "../../queries/Project";
import { secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  clientLabel,
  statusLabel,
} from "../../assets/GlobalLabels";
import { UPDATE_USER_PREFERENCES } from "../../queries/User";
import { useMutation } from "@apollo/client";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 100,
        },
        "&:nth-child(2)": {
          width: 200,
        },
        "&:nth-child(3)": {
          width: 200,
        },
        "&:nth-child(4)": {
          width: 100,
        },
        "&:nth-child(5)": {
          width: 100,
        },
        "&:nth-child(6)": {
          width: 100,
        },
        "&:nth-child(7)": {
          width: 100,
        },
        "&:nth-child(8)": {
          width: 100,
        },
      },
    },
  },
});

export default function ProjectsTable({ projects }) {
  const user = useContext(UserContext);
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);
  const [tableCurrentPage, setTableCurrentPage] = useHistoryState(
    "tableCurrentPage",
    0,
  );
  const tableDataArray = [];

  if (projects == null || projects.forEach == null) {
    projects = [];
  }
  // projects = projects.filter((project) => project.invoice_number);

  projects.forEach((project) => {
    const data = [
      project.job_no,
      project.project_name,
      project.companies_name,
      project.start_on_site,
      poundUK.format(
        project.project_budget_total ? project.project_budget_total : 0,
      ),
      poundUK.format(project.contract_budget ? project.contract_budget : 0),
      project.project_status_text,
      <Tooltip title="View Project" placement="top">
        <IconButton
          aria-label="View"
          component={Link}
          to={{
            pathname: "/projects/single/" + project.project_id,
            state: { fromSearch: true },
          }}
          /*onClick={()=>{
            history.push({
              pathname:"/projects/single/" + project.project_id,
              state:{
                projects:projects
              }
            })
          }}*/
        >
          <RemoveRedEye />
        </IconButton>
      </Tooltip>,
    ];

    tableDataArray.push(data);
  });

  const columnsArray = [
    {
      name: "Job No.",
      options: {
        sort: true,
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          const project = projects[dataIndex];
          return (
            <Link
              style={{ color: secondaryColor }}
              to={"/projects/single/" + project.project_id}
            >
              {project.job_no}
            </Link>
          );
        },
      },
    },
    {
      name: "Project Name",
      options: {
        sort: true,
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          const project = projects[dataIndex];
          return (
            <Link
              style={{ color: secondaryColor }}
              to={"/projects/single/" + project.project_id}
            >
              {project.project_name}
            </Link>
          );
        },
      },
    },
    {
      name: clientLabel,
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "SoS",
      options: {
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          if (!projects[dataIndex].start_on_site) return;

          return new Date(
            projects[dataIndex].start_on_site,
          ).toLocaleDateString();
        },
      },
    },
    {
      name: "Budget",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: "Contract Value",
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: statusLabel,
      options: {
        sort: true,
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          let backgroundColor;
          let status = tableDataArray[dataIndex][6];
          // console.log("status", status);
          if (status == null || status.length === 0) {
            status = "Active";
          }
          switch (status) {
            case "Proposed":
              backgroundColor = "#F6992B";
              break;
            case "Prospect":
              backgroundColor = "#F6992B";
              break;
            default:
              backgroundColor = "#66BC0C";
          }

          return (
            <div
              style={{
                backgroundColor: backgroundColor,
                borderRadius: "4px",
                width: "auto",
                textAlign: "center",
                padding: "0.5em",
                color: "",
                fontSize: "16px",
                fontWeight: "normal",
                textTransform: "none",
              }}
            >
              {status}
            </div>
          );
        },
      },
    },
    {
      name: actionsLabel,
      options: {
        sort: false,
        display: "true",
      },
    },
  ];

  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: "Job No.",
      direction: "desc",
    },
    rowsPerPageOptions: [100, 200, 1000],
    rowsPerPage: user?.preferences?.projectsRowsPerPage,
    onChangePage: (currentPage) => {
      setTableCurrentPage(currentPage);
    },
    page: tableCurrentPage,
    onChangeRowsPerPage: (rowsPerPage) => {
      if (user.preferences == null) {
        user.preferences = {};
      }
      user.preferences.projectsRowsPerPage = rowsPerPage;
      updateUserPreferences({
        variables: {
          _id: user._id,
          preferences: user.preferences,
        },
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  };

  return (
    <ThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        data={tableDataArray}
        columns={columnsArray}
        options={options}
      />
    </ThemeProvider>
  );
}
