import { createContext } from "react";
import { GET_ACTIVE_PROJECT_STATUSES } from "../queries/ProjectStatus";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";

const ProjectStatusContext = createContext();

const ProjectStatusProvider = ({ children }) => {
  const {
    error,
    loading,
    data: activeProjectStatusesData,
    refetch: activeProjectStatusesRefetch,
  } = useQuery(GET_ACTIVE_PROJECT_STATUSES);
  //   const GQL_activeProjectStatuses = useQuery(GET_ACTIVE_PROJECT_STATUSES)

  const [activeProjectStatusesList, setActiveProjectStatusesList] =
    useState(null);
  const [activeProjectStatuses, setActiveProjectStatuses] = useState(null);

  const refetchActiveProjectStatuses = () => {
    activeProjectStatusesRefetch();
  };

  useEffect(() => {
    if (activeProjectStatusesData) {
      setActiveProjectStatusesList(
        activeProjectStatusesData.getActiveProjectStatuses
      );
      const list = activeProjectStatusesData.getActiveProjectStatuses.map(
        (item) => item.name
      );

      setActiveProjectStatuses(list);
    }
  }, [activeProjectStatusesData]);

  return (
    <ProjectStatusContext.Provider
      value={{
        activeProjectStatuses,
        activeProjectStatusesList,
        refetchActiveProjectStatuses,
      }}
    >
      {children}
    </ProjectStatusContext.Provider>
  );
};

export { ProjectStatusContext, ProjectStatusProvider };
