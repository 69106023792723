import React, { useState, Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import useSessionStorage, {
  projectSearchDefaultValue,
  projectSearchKey,
} from "../../reusable/useSessionStorage";

import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
  Popover,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

import { Search } from "@material-ui/icons";

import ProjectsTable from "./ProjectsTable";
import Loading from "../../reusable/Loading";

import { Field, Form } from "react-final-form";

import { FilterList } from "@material-ui/icons";
import { secondaryColor } from "../../assets/ThemeStyling";
import { projectStatuses } from "../../queries/Project";
import { clientLabel } from "../../assets/GlobalLabels";

import { UserContext } from "../../utils/PageWrapper";
import { UPDATE_USER_PREFERENCES } from "../../queries/User";

const GET_PROJECTS = gql`
  query projects(
    $job_no: String
    $project_name: String
    $companies_name: String
    $myProjects: Boolean
    $statusFilters: JSON
  ) {
    projects(
      job_no: $job_no
      project_name: $project_name
      companies_name: $companies_name
      myProjects: $myProjects
      statusFilters: $statusFilters
    ) {
      project_id
      job_no
      project_name
      companies_name
      start_on_site
      project_budget_total
      contract
      project_status_text
      contract_budget
    }
  }
`;

export default function ProjectsSearch() {
  let user = useContext(UserContext);
  if (user == null) {
    user = {};
  }
  if (user.preferences == null) {
    user.preferences = {};
  }

  if (user.preferences.projectFilters == null) {
    user.preferences = { ...user.preferences }; //bizare fix for cannot add property object is not extensible
    user.preferences.projectFilters = {
      myProjects: true,
      Proposed: true,
      Active: true,
      Approved: true,
    };
  }
  const [updateUserPreferences, { loading: updateUserPreferencesLoading }] =
    useMutation(UPDATE_USER_PREFERENCES);

  const [value, setValue] = useSessionStorage(
    projectSearchKey,
    projectSearchDefaultValue,
  );

  const { loading, error, data } = useQuery(GET_PROJECTS, {
    variables: {
      statusFilters: user.preferences.projectFilters,
      myProjects: user.preferences.projectFilters.myProjects,
      ...value.variables,
    },

    fetchPolicy: "network-only", //needed or the oncompleted stop firing and it all breaks
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
  });

  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // console.log("state", value);
  // console.log("user", user);
  // console.log("data", data);

  let view = (
    <Fragment>
      <Typography
        variant="body1"
        style={{ marginBottom: "5px", textAlign: "center" }}
      >
        No results found
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "5px", textAlign: "center" }}
      >
        Search again to see results
      </Typography>
    </Fragment>
  );

  if (error) {
    view = (
      <Fragment>
        <Typography
          variant="body1"
          style={{ marginBottom: "5px", textAlign: "center" }}
        >
          Error
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: "5px", textAlign: "center" }}
        >
          There was an error fetching projects, please try again.
        </Typography>
      </Fragment>
    );
  } else if (loading || updateUserPreferencesLoading) {
    view = <Loading />;
  } else if (data?.projects) {
    view = (
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <ProjectsTable projects={data.projects} />
      </div>
    );
  }

  return (
    <Card style={{ paddingLeft: "0", paddingRight: "0" }}>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false);
          setAnchorEl(null);
        }}
      >
        <div style={{ padding: "20px", minWidth: "300px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="button">Filter</Typography>
            <Button
              color="primary"
              onClick={() => {
                updateUserPreferences({
                  variables: {
                    _id: user._id,
                    preferences: {
                      ...user.preferences,
                      projectFilters: {
                        myProjects: true,

                        Proposed: true,
                        Active: true,
                        Approved: true,
                      },
                    },
                  },
                });
              }}
            >
              Reset
            </Button>
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.preferences.projectFilters.myProjects}
                  onChange={() => {
                    updateUserPreferences({
                      variables: {
                        _id: user._id,
                        preferences: {
                          ...user.preferences,
                          projectFilters: {
                            ...user.preferences.projectFilters,
                            myProjects:
                              !user.preferences.projectFilters.myProjects,
                          },
                        },
                      },
                    });
                  }}
                  value="myProjects"
                  color="secondary"
                />
              }
              label="My Projects"
            />
          </FormGroup>
          {projectStatuses.map((status) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.preferences.projectFilters[status]}
                      onChange={() => {
                        updateUserPreferences({
                          variables: {
                            _id: user._id,
                            preferences: {
                              ...user.preferences,
                              projectFilters: {
                                ...user.preferences.projectFilters,
                                [status]:
                                  !user.preferences.projectFilters[status],
                              },
                            },
                          },
                        });
                      }}
                      value={status}
                      color="secondary"
                    />
                  }
                  label={status}
                />
              </FormGroup>
            );
          })}
        </div>
      </Popover>

      <CardContent style={{ paddingLeft: "0", paddingRight: "0" }}>
        <Form
          initialValues={value.variables}
          onSubmit={(values) => {
            setValue({ ...value, variables: values });
            updateUserPreferences({
              variables: {
                _id: user._id,
                preferences: {
                  ...user.preferences,
                  projectFilters: {
                    ...user.preferences.projectFilters,
                    myProjects: false,
                  },
                },
              },
            });
          }}
          mutators={{
            setJobNo: (args, state, utils) => {
              utils.changeValue(state, "job_no", () => args[0]);
            },
            setProjectName: (args, state, utils) => {
              utils.changeValue(state, "project_name", () => args[0]);
            },
            setCompaniesName: (args, state, utils) => {
              utils.changeValue(state, "companies_name", () => args[0]);
            },
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { setJobNo, setProjectName, setCompaniesName },
            },
            submitting,
          }) => (
            <form onSubmit={handleSubmit} style={{}}>
              <Grid container spacing={3} style={{ width: "100%" }}>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "2em",
                  }}
                >
                  <Button
                    fullWidth
                    type="submit"
                    // loading={getCustomersLoading}
                    disabled={submitting}
                  >
                    <Search style={{ fill: secondaryColor }} />
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Field name="job_no">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        onChange={({ target: { value } }) => {
                          input.onChange(value);
                          setProjectName("");
                          setCompaniesName("");
                        }}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label="Job Number"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="project_name">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        onChange={({ target: { value } }) => {
                          input.onChange(value);
                          setJobNo("");
                          setCompaniesName("");
                        }}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label="Project Name"
                        fullWidth
                        variant="outlined"
                        color="secondary"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="companies_name">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        onChange={({ target: { value } }) => {
                          input.onChange(value);
                          setJobNo("");
                          setProjectName("");
                        }}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label={clientLabel}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title="Filter" placement="bottom">
                    <IconButton
                      onClick={(event) => {
                        setFilterOpen(true);
                        setAnchorEl(event.currentTarget);
                      }}
                      id="filter-button"
                    >
                      <FilterList />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </form>
          )}
        />
        <Grid container spacing={3}>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" style={{ color: "#626C79" }}>
              Applied Filters:
              {user.preferences.projectFilters.myProjects && (
                <Button
                  onClick={() => {
                    updateUserPreferences({
                      variables: {
                        _id: user._id,
                        preferences: {
                          ...user.preferences,
                          projectFilters: {
                            ...user.preferences.projectFilters,
                            myProjects: false,
                          },
                        },
                      },
                    });
                  }}
                >
                  My Projects
                </Button>
              )}
              {projectStatuses.map((status) => {
                let view;

                if (user.preferences.projectFilters[status]) {
                  view = (
                    <Button
                      onClick={() => {
                        let newFilters = { ...user.preferences.projectFilters };
                        newFilters[status] = false;

                        updateUserPreferences({
                          variables: {
                            _id: user._id,
                            preferences: {
                              ...user.preferences,
                              projectFilters: newFilters,
                            },
                          },
                        });
                      }}
                    >
                      {status}
                    </Button>
                  );
                }

                return view;
              })}
            </Typography>
          </Grid>
        </Grid>

        {view}
      </CardContent>
    </Card>
  );
}
