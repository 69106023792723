import gql from "graphql-tag";

export const GET_PROJECT_STATUSES = gql`
  query getProjectStatuses {
    getProjectStatuses {
      name
      position
      active
      archive
      _id
    }
  }
`;
export const GET_ACTIVE_PROJECT_STATUSES = gql`
  query getActiveProjectStatuses {
    getActiveProjectStatuses {
      name
      position
      active
      archive
      _id
    }
  }
`;
export const GET_PROJECT_STATUS = gql`
  query getProjectStatus($projectStatusId: ID!) {
    getProjectStatus(_id: $projectStatusId) {
      name
      position
      active
      archive
      _id
    }
  }
`;

export const ADD_PROJECT_STATUS = gql`
  mutation addProjectStatus(
    $name: String!
    $archive: Boolean!
    $active: String!
  ) {
    addProjectStatus(name: $name, archive: $archive, active: $active) {
      name
      position
      active
      archive
      _id
    }
  }
`;

export const UPDATE_PROJECT_STATUS = gql`
  mutation updateProjectStatus(
    $projectStatusId: ID!
    $name: String!
    $position: Int!
    $archive: Boolean!
    $active: String!
  ) {
    updateProjectStatus(
      _id: $projectStatusId
      name: $name
      position: $position
      archive: $archive
      active: $active
    ) {
      _id
      position
      name
      archive
      active
    }
  }
`;
