import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import Typography from "./Typography";

const StyledMenuItem = styled.div`
  padding: 16px;
  display: block;
  text-decoration: none;
  /* Add hover styles if this is a link or a function */
  &:hover,
  &:active {
    background-color: ${({ onClick, as, disabled }) => {
      const isLink = !!onClick || as?.displayName === "Link";

      if (isLink && !disabled) return rgba("#7b7b7b", 0.1);
    }};
    cursor: ${({ onClick, as, disabled }) => {
      const isLink = !!onClick || as?.displayName === "Link";

      if (disabled) return "not-allowed";

      if (isLink) return "pointer";
    }};
  }
`;

export default function MenuItem({
  children,
  component,
  disabled,
  onClick,
  ...rest
}) {
  return (
    <StyledMenuItem
      as={component}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      <Typography variant="bodySmall" colour={disabled ? "#7b7b7b" : undefined}>
        {children}
      </Typography>
    </StyledMenuItem>
  );
}
