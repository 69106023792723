import React, { useState, useEffect } from "react";
import ReactSpinner from "react-spinjs-new";

export default function Loading() {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => setDisplay(true), 250);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (display) {
    return (
      <div
        style={{
          height: "150px",
          width: "150px",
          position: "relative",
          margin: "auto",
        }}
      >
        <ReactSpinner
          config={{
            lines: 16,
            length: 0,
            width: 21,
            radius: 54,
            scale: 1,
            speed: 0.8,
            color: "#c0c0c0",
            fadeColor: "transparent",
          }}
        />
        <ReactSpinner
          config={{
            lines: 16,
            length: 0,
            width: 13,
            radius: 35,
            scale: 1,
            speed: 0.8,
            color: "#c0c0c0",
            fadeColor: "transparent",
          }}
        />
        <div
          style={{
            height: "50px",
            width: "50px",
            backgroundColor: "#dddddd",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translateX(-50%) translateY(-50%)",
            borderRadius: "50%",
          }}
        />
      </div>
    );
  }
  return null;
}
