import React, { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../utils/PageWrapper";
import { GET_USERS, UPDATE_USER_PREFERENCES } from "../../queries/User";
import { useHistoryState } from "../../queries/Project";
import ModeSelect from "../User/ModeSelect";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@material-ui/styles";
import { useMutation, useQuery } from "@apollo/client";

import { secondaryColor } from "../../assets/ThemeStyling";
import { useHistory } from "react-router";
import { ProjectStatusContext } from "../../context/ProjectStatusContext";

import {
  achieveLabel,
  actionsLabel,
  positionLabel,
  statusLabel,
  statusNameLabel,
} from "../../assets/GlobalLabels";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { openDialog } from "../../reusable/ScrollablePopup";
import EditContactModal from "../Contacts/EditContactModal";
import ModeIcon from "@mui/icons-material/Mode";

import {
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Tooltip,
  createTheme,
  Breadcrumbs,
} from "@material-ui/core";
import NewProjectStatusModal from "./NewProjectStatusModal";
import { GET_PROJECT_STATUSES } from "../../queries/ProjectStatus";
import { useEffect } from "react";
import EditProjectStatusModal from "./EditProjectStatusModal";
import Loading from "../../reusable/Loading";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 50,
        },
        "&:nth-child(2)": {
          width: 500,
        },
        "&:nth-child(3)": {
          width: 50,
        },
        "&:nth-child(4)": {
          width: 50,
        },
        "&:nth-child(5)": {
          width: 50,
        },
      },
    },
  },
});

const ProjectStatusList = () => {
  const history = useHistory();
  const { refetchActiveProjectStatuses } = useContext(ProjectStatusContext);

  const { error, loading, data, refetch } = useQuery(GET_PROJECT_STATUSES);
  const [list, setList] = useState(null);

  useEffect(() => {
    if (data) {
      setList(data.getProjectStatuses);
      let formattedProjectStatusArray = data.getProjectStatuses.map(
        (status) => {
          //format customers for table
          return [
            status.position,
            status.name,
            status.archive,
            status.active,
            status._id,
          ];
        }
      );

      setList(formattedProjectStatusArray);
    }
  }, [data]);

  const columns = [
    positionLabel,
    statusNameLabel,
    {
      name: achieveLabel,
      options: {
        customBodyRender: (status) => {
          if (status) {
            return <DoneOutlinedIcon sx={{ color: "#BB4430" }} />;
          }

          return null;
        },
      },
    },
    {
      name: statusLabel,
      options: {
        customBodyRender: (status) => {
          let backgroundColor = "#66BC0C";
          if (status == null || status.length === 0) {
            status = "Active";
          }
          switch (status) {
            case "Disabled":
              backgroundColor = "#e02020";
              break;
            default:
              break;
          }
          return (
            <div
              style={{
                backgroundColor: backgroundColor,
                borderRadius: "4px",
                width: "auto",
                textAlign: "center",
                paddingTop: "0.5em",
                paddingBottom: "0.5em",
                color: "",
                fontSize: "16px",
                fontWeight: "normal",
                textTransform: "none",
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
              }}
            >
              {status}
            </div>
          );
        },
      },
    },
    {
      name: actionsLabel,
      options: {
        customBodyRender: (_id) => {
          return (
            <IconButton
              onClick={() => {
                openDialog({
                  content: (
                    <EditProjectStatusModal
                      projectStatusId={_id}
                      onUpdate={handleOnAdded}
                    />
                  ),
                });
              }}
            >
              <ModeIcon style={{ fill: secondaryColor }}></ModeIcon>
            </IconButton>
          );
        },
      },
    },
  ];

  const handleOnAdded = () => {
    refetch();
    refetchActiveProjectStatuses();
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",

    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    sortOrder: {
      name: positionLabel,
      direction: "asc",
    },
  };

  const user = useContext(UserContext);
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);
  const [tableCurrentPage, setTableCurrentPage] = useHistoryState(
    "tableCurrentPage",
    0
  );

  let view = (
    <Typography variant="body1" style={{ color: "grey" }}>
      Search for a project status or create a new one
    </Typography>
  );

  if (loading || !data?.getProjectStatuses) {
    view = <Loading />;
  }

  if (data?.getProjectStatuses) {
    view = (
      <ThemeProvider theme={columnWidthTheme}>
        <MUIDataTable
          title=""
          data={list || []}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    );
  }

  return (
    <Grid container spacing={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ fontSize: "14px", padding: "12px" }}
      >
        <Typography
          color="text.primary"
          style={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            history.push("/admin-settings");
          }}
        >
          Settings
        </Typography>

        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          Project Status - Overview
        </Typography>
      </Breadcrumbs>

      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <ModeSelect /> */}
          <Typography component="h1" variant="h4">
            Project Status - Overview
          </Typography>
          <Button
            variant="contained"
            style={{
              marginLeft: "auto",
            }}
            color="primary"
            onClick={() => {
              openDialog({
                content: <NewProjectStatusModal onAdded={handleOnAdded} />,
              });
            }}
          >
            New Status
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        {view}
      </Grid>
    </Grid>
  );
};

export default ProjectStatusList;
