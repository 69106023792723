import React from "react";
import MUIDataTable from "mui-datatables";

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import Loading from "../../reusable/Loading";

import emptySearch from "../../assets/images/empty-table-illustration.svg";

import {
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@material-ui/core";

import { Search } from "@material-ui/icons";
import { Field, FormSpy } from "react-final-form";
import Pagination from "@mui/material/Pagination";

import { useStore } from "./WizPageStore";
import { Box } from "@mui/system";
import PageSwitch from "./PageSwitch";
import { secondaryColor } from "../../assets/ThemeStyling";
import { nameLabel, statusLabel } from "../../assets/GlobalLabels";

const GET_COMPANIES = gql`
  query companies($name: String) {
    companies(name: $name) {
      id
      name
      status
    }
  }
`;

const CustomFooter = (props) => {
  const handleChange = (event, value) => {
    props.changePage(value);
  };

  return (
    <Grid container justifyContent="center">
      <Pagination
        count={Math.floor(props.rowCount / 10)}
        page={props.page}
        onChange={handleChange}
        showFirstButton
        showLastButton
        style={{ marginTop: "12px" }}
      />
    </Grid>
  );
};

export default function ExistingCompanies() {
  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);

  const [
    getCompanies,
    { loading: getCompaniesLoading, error: getCompaniesError, data },
  ] = useLazyQuery(GET_COMPANIES);

  // body {
  //   display: flex;
  //   flex-direction: column;
  // }
  // .content {
  //   flex: 1 0 auto;
  // }
  // .footer {
  //   flex-shrink: 0;
  // }

  return (
    <FormSpy
    //  subscription={{ values: true, pristine: true, submitting: true }}
    >
      {({
        values,
        pristine,
        submitting,
        form: {
          mutators: { setCompanyDetails },
        },
      }) => (
        <Box
          //main container
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            //content container
            container
            spacing={3}
            style={{ flex: "1 0 auto" }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                minWidth: "600px",
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,

                  fontFamily: "Poppins",
                }}
              >
                Select Existing Client
              </Typography>

              <Button
                variant="contained"
                color="primary"
                // type="submit"
                onClick={next}
                style={{ marginTop: "12px", marginRight: "16px" }}
              >
                Create New Client
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="search_name"
                placeholder="Search for a client"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getCompanies({
                      variables: { name: values.search_name },
                    });
                  }
                }}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    // label="Search for a client"
                    fullWidth
                    // required
                    // size="small"
                    // variant="outlined"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button
                            fullWidth
                            type="submit"
                            disabled={submitting}
                            onClick={(e) => {
                              e.preventDefault();
                              getCompanies({
                                variables: { name: values.search_name },
                              });
                            }}
                          >
                            <Search style={{ fill: secondaryColor }} />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} style={{ padding: "0" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: data ? "1px" : "350px",
                }}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  {(() => {
                    if (getCompaniesLoading) {
                      return <Loading />;
                    } else if (getCompaniesError) {
                      return (
                        <Typography variant="body1">
                          Failed to get customers, please try again.
                        </Typography>
                      );
                    } else if (data) {
                      return (
                        <MUIDataTable
                          components={{
                            TableFooter: CustomFooter,
                          }}
                          data={data.companies.map((company) => {
                            let status = (
                              <span
                                style={{
                                  backgroundColor: "#66BC0C",
                                  padding: "8px 12px",
                                  borderRadius: "4px",
                                  fontSize: "16px",
                                }}
                              >
                                Active
                              </span>
                            );

                            if (company.status === "Prospective") {
                              status = (
                                <span
                                  style={{
                                    backgroundColor: "#F6992B",
                                    padding: "8px 12px",
                                    borderRadius: "4px",
                                    fontSize: "16px",
                                  }}
                                >
                                  Prospect
                                </span>
                              );
                            }

                            return [company.name, status];
                          })}
                          columns={[nameLabel, statusLabel]}
                          options={{
                            selectableRows: "single",
                            selectToolbarPlacement: "none",
                            print: false,
                            download: false,
                            responsive: "standard",
                            search: false,
                            filter: false,
                            elevation: 0,
                            viewColumns: false,
                            rowsSelected: values.selectedIndexes,
                            onRowSelectionChange: (
                              currentRowsSelected,
                              allRowsSelected,
                              rowsSelected,
                            ) => {
                              const selectedIndex = currentRowsSelected.map(
                                (index) => index.index,
                              );

                              const selectedCompany =
                                data.companies[selectedIndex];

                              setCompanyDetails(
                                selectedCompany.id,
                                selectedCompany.name,
                                selectedIndex,
                              );
                            },
                          }}
                        />
                      );
                    } else
                      return (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={emptySearch} alt="React Logo" />

                          <Typography
                            variant="body1"
                            style={{
                              color: "grey",
                              marginLeft: "12px",
                              marginTop: "12px",
                            }}
                          >
                            Search for a customer or create a new one.
                          </Typography>
                        </Grid>
                      );
                  })()}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* 
          <Grid
            //footer container
            container
            style={{ flex: "1 0 auto" }}
          >
            <Grid item xs={12}>
              <Divider
                style={{
                  marginBottom: "24px",
                  marginLeft: "-24px",
                  marginRight: "-24px",
                  backgroundColor: "#C3CAD8",
                }}
              />
            </Grid>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button disabled={true} variant="outlined">
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  submitting ||
                  (page === 1 && !values.companyId) ||
                  (page === 0 && !values.companyId)
                }
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </Grid> */}

          <PageSwitch
            disabled={
              submitting ||
              (page === 1 && !values.companyId) ||
              (page === 0 && !values.companyId)
            }
            submit
            disabledBack
          />
        </Box>
      )}
    </FormSpy>
  );
}
