import React, { Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import { Field, Form } from "react-final-form";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { FormControl, InputLabel, Select } from "@mui/material";

import { Search } from "@material-ui/icons";

import DatePicker from "../../../uicomponents/DatePicker";

import { addMonths } from "date-fns";
import { secondaryColor } from "../../../assets/ThemeStyling";
import { projectStatuses } from "../../../queries/Project";
import { filterHidden, GET_USERS, sortByName } from "../../../queries/User";
import Loading from "../../../reusable/Loading";
import useSessionStorage, {
  ganttSearchKey,
  ganttSearchDefaultValue,
} from "../../../reusable/useSessionStorage";
import { UserContext } from "../../../utils/PageWrapper";

import { services } from "./helper";
import GanttChart from "./GanttChart";

const GET_PROJECTS = gql`
  query projectsForGanttChart(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $user_id: String
    $service: String
    $filters: [String]
    $sortBy: String
    $sortDesc: Boolean
  ) {
    projectsForGanttChart(
      dateFrom: $dateFrom
      dateTo: $dateTo
      user_id: $user_id
      service: $service
      filters: $filters
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      project {
        _id
        project_id
        job_no
        project_name
        companies_name
        start_on_site
        est_handover_date
        city
        project_status_text
      }

      duration

      projectUsers {
        design {
          _id
          role
          initials
          userInfo {
            first_name
            last_name
          }
        }
        qs {
          _id
          role
          initials
          userInfo {
            first_name
            last_name
          }
        }
        pm {
          _id
          role
          initials
          userInfo {
            first_name
            last_name
          }
        }
      }
    }
  }
`;

const newDate = new Date();
const oneMonthAgo = addMonths(newDate, -1);

export default function ProjectsGanttSearch() {
  let user = useContext(UserContext);

  let userStatuses = [];
  if (user?.preferences?.projectFilters) {
    for (const property in user.preferences.projectFilters) {
      if (user.preferences.projectFilters[property] === true) {
        userStatuses.push(property);
      }
    }
  } else {
    userStatuses = ["myProjects", "Proposed", "Active", "Approved"];
  }

  const [value, setValue] = useSessionStorage(
    ganttSearchKey,
    ganttSearchDefaultValue,
  );

  const { loading: usersLoading, data: usersData } = useQuery(GET_USERS);

  const { loading, error, data, refetch } = useQuery(GET_PROJECTS, {
    variables: {
      dateFrom: oneMonthAgo,
      dateTo: newDate,
      filters: user.preferences.projectFilters,
      ...value.variables,
    },

    fetchPolicy: "network-only", //needed or the oncompleted stop firing and it all breaks
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
  });

  let view = (
    <Fragment>
      <Typography
        variant="body1"
        style={{ marginBottom: "5px", textAlign: "center" }}
      >
        No results found
      </Typography>
      <Typography
        variant="body1"
        style={{ marginBottom: "5px", textAlign: "center" }}
      >
        Search again to see results
      </Typography>
    </Fragment>
  );

  if (error) {
    view = (
      <Fragment>
        <Typography
          variant="body1"
          style={{ marginBottom: "5px", textAlign: "center" }}
        >
          Error
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: "5px", textAlign: "center" }}
        >
          There was an error fetching projects, please try again.
        </Typography>
      </Fragment>
    );
  } else if (loading || usersLoading || !user) {
    view = <Loading />;
  } else if (data?.projectsForGanttChart && data?.projectsForGanttChart[0]) {
    view = <GanttChart data={data} refetch={refetch} />;
  }

  return (
    <Card
      style={{
        minHeight: "500px",
        width: "95vw",
        position: "relative",
        left: "50%",
        right: "50%",
        marginLeft: "-47.5vw",
        marginRight: "-47.5vw",

        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={0}
        style={{
          maxWidth: window.innerWidth * 0.9,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12}>
          <CardContent style={{ paddingLeft: "0", paddingRight: "0" }}>
            <Form
              initialValues={{
                ...value.variables,
                dateFrom: oneMonthAgo,
                dateTo: newDate,
                filters: value.variables.filters
                  ? value.variables.filters
                  : userStatuses,
              }}
              onSubmit={(values) => {
                setValue({ ...value, variables: values });
              }}
              render={({
                handleSubmit,
                form,
                values,
                values: { dateFrom },
                submitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "2em",
                    }}
                  >
                    <Grid item xs={1}>
                      <Button
                        fullWidth
                        type="submit"
                        // loading={getCustomersLoading}
                        disabled={submitting}
                      >
                        <Search style={{ fill: secondaryColor }} />
                      </Button>
                    </Grid>

                    <Grid item xs={2}>
                      <DatePicker
                        label="From"
                        required
                        name="dateFrom"
                        showYearDropdown
                        maxDate={newDate}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <DatePicker
                        label="To"
                        required
                        name="dateTo"
                        showYearDropdown
                        minDate={dateFrom}
                        // maxDate={newDate}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <Field name="user_id">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            select
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                            label="User"
                            fullWidth
                            // required
                            variant="outlined"
                          >
                            <MenuItem value="">None</MenuItem>
                            {usersData?.users
                              .filter(filterHidden)
                              .sort(sortByName)
                              .map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  {`${user.first_name} ${user.last_name}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={2}>
                      <Field name="service">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            select
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                            label="Service"
                            fullWidth
                            // required
                            variant="outlined"
                          >
                            <MenuItem value="">None</MenuItem>
                            {services.map((service) => (
                              <MenuItem key={service} value={service}>
                                {service}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={3}>
                      <Field name="filters">
                        {({
                          input: { name, onChange, value, ...restInput },
                          meta,
                          ...rest
                        }) => (
                          <FormControl>
                            {!values.filters[0] && (
                              <InputLabel id="demo-multiple-chip-label">
                                Status Filters
                              </InputLabel>
                            )}
                            <Select
                              style={{
                                maxHeight: "56px",
                                maxWidth: "20vw",
                                minWidth: "200px",
                              }}
                              {...rest}
                              helperText={meta.touched ? meta.error : undefined}
                              error={meta.error && meta.touched}
                              inputProps={restInput}
                              onChange={onChange}
                              value={value}
                              labelId="multiple-name-label"
                              id="multiple-select"
                              multiple
                              input={<OutlinedInput />}
                            >
                              {projectStatuses.map((status) => (
                                <MenuItem key={status} value={status}>
                                  {status}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </form>
              )}
            />
          </CardContent>
        </Grid>

        <Grid item xs={12}>
          {view}
        </Grid>
      </Grid>
    </Card>
  );
}
