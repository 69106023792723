import React from "react";

import { Typography } from "@material-ui/core";

export default function Error() {
  return (
    <Typography variant="body1">
      Something went wrong, please refresh the page and try again
    </Typography>
  );
}
