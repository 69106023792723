import React from "react";
import { FieldArray } from "react-final-form-arrays";
import MUIDataTable from "mui-datatables";
import { useQuery } from "@apollo/client";
import Loading from "../../reusable/Loading";
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  FormControlLabel,
  Checkbox,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Delete } from "@material-ui/icons";
import { Field, FormSpy } from "react-final-form";
import { useStore } from "./WizPageStore";
import {
  roles,
  GET_USERS,
  filterHidden,
  filterOnRole,
  sortByName,
} from "../../queries/User";
import PageSwitch from "./PageSwitch";
import { Box } from "@mui/system";
import { primaryColor, secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  leadLabel,
  nameLabel,
  roleLabel,
} from "../../assets/GlobalLabels";

const columnWidthTheme = createTheme({
  palette: {
    primary: { main: primaryColor, contrastText: "#ffffff" },
    secondary: { main: secondaryColor },
    text: {
      secondary: "#68727E",
    },
    action: {
      active: secondaryColor,
      focus: secondaryColor,
      selected: secondaryColor,
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 200,
        },
        "&:nth-child(2)": {
          width: 200,
        },
        "&:nth-child(3)": {
          width: 100,
        },
        "&:nth-child(4)": {
          width: 60,
        },
      },
    },
  },
});

const CustomFooter = (props) => {
  // const handleChange = (event, value) => {
  //   props.changePage(value);
  // };
  return (
    <></>
    // <Grid container justifyContent="center">
    //   <Pagination
    //     count={Math.floor(props.rowCount / 10)}
    //     page={props.page}
    //     onChange={handleChange}
    //     showFirstButton
    //     showLastButton
    //     style={{ marginTop: "12px" }}
    //   />
    // </Grid>
  );
};

<Field name="addressPostcode">
  {({ input, meta }) => (
    <TextField
      {...input}
      label="Postcode"
      fullWidth
      // required
      variant="outlined"
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ""}
    />
  )}
</Field>;

export default function InternalContacts() {
  const { loading, error, data } = useQuery(GET_USERS);

  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);

  // console.log("users data", data);

  // let view = (
  //   <Typography variant="body1" style={{ color: "grey" }}>
  //     Search for a company or create a new one
  //   </Typography>
  // );

  // if (loading) {
  //   view = <Loading></Loading>;
  // }

  // if (error) {
  //   view = (
  //     <Typography variant="body1">
  //       Failed to get companies, please try again.
  //     </Typography>
  //   );
  // }

  return loading ? (
    <Loading />
  ) : (
    <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
      {({
        values,
        pristine,
        submitting,
        form: {
          mutators: { resetProjectSelectedValues },
        },
      }) => (
        <Box
          //main container
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            //content container
            container
            spacing={3}
            style={{ flex: "1 0 auto" }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                Project Contacts - Internal
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Field name="selected_role">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label="Select Role"
                        fullWidth
                        // required
                        size="small"
                        variant="outlined"
                        // style={{ paddingBottom: "15px" }}
                      >
                        {roles.map((role) => (
                          <MenuItem value={role}>{role}</MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>

                <FieldArray name="projectUsers">
                  {({ fields }) => (
                    <>
                      <Grid item xs={5}>
                        <Field name="selected_user">
                          {({ input, meta, ...rest }) => (
                            <TextField
                              {...input}
                              // {...rest}
                              // value={input.value.user_name}
                              // onChange={(event, value) => input.onChange(value)}
                              select
                              size="small"
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                              label="Select Staff Member"
                              fullWidth
                              // required
                              variant="outlined"
                              // style={{ paddingBottom: "15px" }}
                            >
                              {data.users
                                .filter(filterHidden)
                                .filter(filterOnRole(values.selected_role))
                                .sort(sortByName)
                                .map((user) => (
                                  <MenuItem value={user._id}>
                                    {`${user.first_name} ${user.last_name}`}
                                  </MenuItem>
                                ))}
                            </TextField>
                          )}
                        </Field>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Add" placement="top">
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={
                              !data.users.length ||
                              !values.selected_role ||
                              !values.selected_user
                            }
                            onClick={() => {
                              fields.push({
                                ...data?.users?.find(
                                  (user) => user._id === values.selected_user,
                                ),
                                role: values.selected_role,

                                lead: !values.projectUsers?.find(
                                  (user) => user.role === values.selected_role,
                                ),
                              });
                              resetProjectSelectedValues();
                            }}
                          >
                            Add
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} style={{ padding: "0" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: data ? "1px" : "350px",
                          }}
                        >
                          <div style={{ width: "100%", height: "100%" }}>
                            {values.projectUsers && values.projectUsers[0] ? (
                              <ThemeProvider theme={columnWidthTheme}>
                                <MUIDataTable
                                  // title="Existing Contacts"
                                  // pagination={false}
                                  components={{
                                    TableFooter: CustomFooter,
                                  }}
                                  data={fields.map((name, index) => {
                                    // console.log("user", name);

                                    const remove = (
                                      <Tooltip
                                        title="Remove"
                                        placement="top"
                                        style={{
                                          display: "flex",

                                          justifyContent: "center",
                                        }}
                                      >
                                        <IconButton
                                          aria-label="Remove"
                                          onClick={() => fields.remove(index)}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    );

                                    const lead = (
                                      <Field
                                        name={`${name}.lead`}
                                        type="checkbox"
                                      >
                                        {({ input }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                inputProps={{ name: "design" }}
                                                {...input}
                                                value="design"
                                              />
                                            }
                                            label={leadLabel}
                                          />
                                        )}
                                      </Field>
                                    );

                                    return [
                                      values.projectUsers[index].first_name +
                                        " " +
                                        values.projectUsers[index].last_name,
                                      values.projectUsers[index].role,
                                      lead,
                                      remove,
                                    ];
                                  })}
                                  columns={[
                                    nameLabel,
                                    roleLabel,
                                    leadLabel,
                                    actionsLabel,
                                  ]}
                                  options={{
                                    selectableRows: "none",
                                    print: false,
                                    download: false,
                                    responsive: "standard",
                                    search: false,
                                    filter: false,
                                    elevation: 0,
                                    viewColumns: false,
                                    searchPlaceholder: "Please add contacts",
                                  }}
                                />
                              </ThemeProvider>
                            ) : (
                              <Typography
                                variant="body1"
                                style={{
                                  color: "grey",
                                  marginLeft: "12px",
                                  marginTop: "12px",
                                }}
                              >
                                Please add a staff member.
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
          </Grid>

          <PageSwitch disabled={submitting} submit />
        </Box>
      )}
    </FormSpy>
  );
}
