import React from "react";

import UIListEntryManager from "./UIListEntryManager";

export default function UIListSettings() {
  /*<UIListEntryManager
      title="Discount/Increase Reason Codes"
      type="discountsincreases"
    />*/
  return <></>;
}
