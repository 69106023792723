import styled from "styled-components";

const FlexBox = styled.div`
  display: flex;
  align-items: ${({ alignItems = "center" }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection = "row" }) => flexDirection};
  height: 100%;
  cursor: ${({ onClick }) => {
    if (onClick) return "pointer !important";
  }};
`;

export default FlexBox;
