import React from "react";

import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Divider,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Tooltip,
} from "@material-ui/core";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { openSnackbar } from "../../reusable/Notifier";

import { Form, Field } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";

import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { GET_CONTACTS, contactRoles } from "../../queries/Customer";
import {
  contactNumberLabel,
  emailAddressLabel,
} from "../../assets/GlobalLabels";

const handleSubmit = () => {};

const ADD_PROJECT_CONTACT = gql`
  mutation addProjectContact(
    $project_id: ID!
    $contact_id: ID!
    $role: String!
  ) {
    addProjectContact(
      project_id: $project_id
      contact_id: $contact_id
      role: $role
    ) {
      _id
    }
  }
`;

const CREATE_CONTACT = gql`
  mutation newContact(
    $contact_name: String!
    $contact_email: String
    $contact_number: String
    $contact_position: String
    $contact_company: String
    $addToProject: Boolean
    $project_id: ID
  ) {
    newContact(
      contact_name: $contact_name
      contact_email: $contact_email
      contact_number: $contact_number
      contact_position: $contact_position
      contact_company: $contact_company
      addToProject: $addToProject
      project_id: $project_id
    ) {
      _id
      contact_name
      contact_email
      contact_position
      contact_number
      contact_company
    }
  }
`;

const ProjectPeopleAddContactModal = ({ project, onAdded }) => {
  const [addProjectContact, { adding, addingError, addingData }] = useMutation(
    ADD_PROJECT_CONTACT,
    {
      onCompleted: () => {
        openSnackbar({
          message: "Contact has been added.",
          type: "success",
        });

        //next();
        onAdded();
        closeDialog();
      },
      onError: (error) => {
        console.log(error);
        openSnackbar({
          message: "Failed to add contact.",
          type: "error",
        });
      },
    },
  );

  const [
    newContact,
    {
      loading: newContactLoading,
      error: newContactError,
      data: newContactData,
    },
  ] = useMutation(CREATE_CONTACT, {
    refetchQueries: [
      {
        query: GET_CONTACTS,
        variables: {
          contact_company: project.client,
        },
      },
    ],
    onError: () => {
      openSnackbar({
        message: "Error adding contact, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      onAdded();
      closeDialog();
      openSnackbar({
        message: "Contact added successfully",
        type: "success",
      });
    },
  });

  const { loading, error, data } = useQuery(GET_CONTACTS, {
    variables: {
      contact_company: project.client,
    },
  });

  // console.log("contacts", data);

  if (loading || newContactLoading || data == null) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage />;
  }
  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, submitting, values, form }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Add Client Contact
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Field name="role">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                  label="Select Role"
                  fullWidth
                  // required
                  variant="outlined"
                  // style={{ paddingBottom: "15px" }}
                >
                  {contactRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={6}>
            {data.contacts.length > 0 ? (
              <Field name="contact_id">
                {({ input, meta, ...rest }) => (
                  <TextField
                    {...input}
                    // {...rest}
                    // value={input.value.user_name}
                    // onChange={(event, value) => input.onChange(value)}
                    select
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    label="Select Client Contact"
                    fullWidth
                    // required
                    variant="outlined"
                    // style={{ paddingBottom: "15px" }}
                  >
                    {data.contacts.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.contact_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
            ) : (
              <Typography
                variant="body1"
                style={{ textAlign: "center", paddingTop: "0.75em" }}
              >
                No contacts. Please create a new one.
              </Typography>
            )}
          </Grid>

          <Accordion
            style={{
              padding: "12px",
            }}
          >
            <Tooltip title="Add" placement="top">
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "40px",
                }}
                // onClick={() => {}}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  create new contact
                </AccordionSummary>
              </Button>
            </Tooltip>

            <AccordionDetails
              style={{
                padding: "0",
                paddingTop: "16px",
              }}
            >
              <Form
                onSubmit={() => {}}
                initialValues={{}}
                validate={(values) => {
                  const errors = {};

                  if (!values.new_contact_first_name) {
                    errors.new_contact_first_name = "Required";
                  }
                  if (!values.new_contact_last_name) {
                    errors.new_contact_last_name = "Required";
                  }
                  if (!values.new_contact_job_role) {
                    errors.new_contact_job_role = "Required";
                  }

                  return errors;
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                  valid,
                }) => {
                  return (
                    <form id="myForm" onSubmit={handleSubmit}>
                      {
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Field name="new_contact_first_name">
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    label="First Name"
                                    fullWidth
                                    size="small"
                                    required
                                    variant="outlined"
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={6}>
                              <Field name="new_contact_last_name">
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    label="Last Name"
                                    fullWidth
                                    size="small"
                                    required
                                    variant="outlined"
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={6}>
                              <Field name="new_contact_job_role">
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    label="Job Role"
                                    fullWidth
                                    size="small"
                                    required
                                    variant="outlined"
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={6}>
                              <Field name="new_contact_contact_number">
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    label={contactNumberLabel}
                                    fullWidth
                                    size="small"
                                    // required
                                    variant="outlined"
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={6}>
                              <Field name="new_contact_contact_email">
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    label={emailAddressLabel}
                                    fullWidth
                                    size="small"
                                    // required
                                    variant="outlined"
                                    error={meta.error && meta.touched}
                                    helperText={
                                      meta.error && meta.touched
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (valid) {
                                    newContact({
                                      variables: {
                                        contact_name: `${values.new_contact_first_name} ${values.new_contact_last_name}`,
                                        contact_email:
                                          values.new_contact_contact_email,
                                        contact_position:
                                          values.new_contact_job_role,
                                        contact_number:
                                          values.new_contact_contact_number,
                                        contact_company: project.client,

                                        project_id: project._id,
                                        addToProject: true,
                                      },
                                    });

                                    form.reset();
                                  }
                                }}
                                // disabled={
                                //   submitting ||
                                //   (page === 1 && !values.companyId) ||
                                //   (page === 0 && !values.companyId)
                                // }
                                type="submit"
                              >
                                create
                              </Button>

                              <Button
                                // disabled={true}
                                style={{ marginLeft: "24px" }}
                                onClick={() => {
                                  form.reset();
                                }}
                                variant="outlined"
                              >
                                cancel
                              </Button>
                            </Grid>
                          </Grid>

                          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        </>
                      }
                    </form>
                  );
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                values.project_id = project._id;
                addProjectContact({ variables: values });
              }}
              disabled={
                submitting || values.role == null || values.contact_id == null
              }
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

export default ProjectPeopleAddContactModal;
