import React, { useEffect, useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form } from "react-final-form";
import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import { ADD_COMPANY } from "../../queries/Customer";
import { useMutation, useQuery } from "@apollo/client";
import { closeDialog } from "../../reusable/ScrollablePopup";
import { openSnackbar } from "../../reusable/Notifier";
import {
  clientNameLabel,
  positionLabel,
  statusNameLabel,
} from "../../assets/GlobalLabels";
import {
  ADD_PROJECT_STATUS,
  GET_PROJECT_STATUS,
  UPDATE_PROJECT_STATUS,
} from "../../queries/ProjectStatus";
// import { Switch } from 'react-router-dom/cjs/react-router-dom.min'

const EditProjectStatusModal = ({ projectStatusId, onUpdate }) => {
  const [initialValues, setInitialValues] = useState({});
  const { loading, error, data } = useQuery(GET_PROJECT_STATUS, {
    variables: { projectStatusId: projectStatusId },
  });

  useEffect(() => {
    if (data) {
      const { active, name, archive, position } = data.getProjectStatus;

      setInitialValues({
        active: active === "Active" ? true : false,
        archive: archive,
        statusName: name,
        position: position,
      });
    }
  }, [data]);

  const [updateProjectStatus] = useMutation(UPDATE_PROJECT_STATUS, {
    onCompleted: () => {
      openSnackbar({
        message: "Project status has been updated.",
        type: "success",
      });
      onUpdate();
      closeDialog();
    },
    onError: (error) => {
      console.error(error);
      openSnackbar({
        message: "Failed to add the project status.",
        type: "error",
      });
    },
  });

  const onSubmit = (values) => {
    const { statusName, archive, position, active } = values;

    updateProjectStatus({
      variables: {
        name: statusName,
        archive: archive || false,
        active: active ? "Active" : "Disabled",
        position: parseInt(position),
        projectStatusId: projectStatusId,
      },
    });
  };

  const renderForm = ({ handleSubmit, submitting, values }) => (
    <form onSubmit={handleSubmit}>
      <>
        <Grid container spacing={3} justify="center" style={{ padding: "2em" }}>
          <div style={{ width: "100%", textAlign: "left" }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                textAlign: "left",
                paddingLeft: "0.5em",
              }}
            >
              Edit Project Status
            </Typography>
          </div>
          <Grid item xs={12}>
            <Field name="statusName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  // inputRef={(input) => input && input.focus()}
                  label={statusNameLabel}
                  fullWidth
                  required={true}
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="position">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  // inputRef={(input) => input && input.focus()}
                  label={positionLabel}
                  fullWidth
                  required={true}
                  type="number"
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="archive" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "archive" }}
                      {...input}
                      value="archive"
                    />
                  }
                  label="Should projects with this status have their files archived automatically?"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="active" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ name: "archive" }}
                      {...input}
                      color="primary"
                      value="active"
                    />
                  }
                  label={`Project status is  ${
                    values.active ? "Active" : "Disabled"
                  }`}
                  labelPlacement="Start"
                />
              )}
            </Field>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Divider
              style={{
                // marginTop: "25px",
                marginBottom: "12px",
                marginLeft: "-12px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "13px",
              marginBottom: "12px",
              paddingLeft: "0.75em",
              paddingRight: "0.75em",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                submitting ||
                values.statusName == null ||
                values.statusName.length === 0 ||
                values.position == null ||
                values.position.length === 0 ||
                values.position < 1
              }
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </>
    </form>
  );

  return (
    <Card
      style={{
        padding: "0",
        border: "none",
        boxShadow: "none",
        borderRadius: "8px",
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={renderForm}
      />
    </Card>
  );
};

export default EditProjectStatusModal;
