import React from "react";

import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import { openSnackbar } from "../../reusable/Notifier";

import { Grid, Typography, Divider } from "@material-ui/core";
import { labelColour } from "../../queries/Project";
import { FormSpy } from "react-final-form";

import { closeDialog } from "../../reusable/ScrollablePopup";
import PageSwitch from "./PageSwitch";
import { Box } from "@mui/system";

const ADD_PROJECT = gql`
  mutation addProject(
    $companyId: String
    $companies_name: String
    $project_name: String
    $address_1: String
    $address_2: String
    $city: String
    $postcode: String
    $services_hs: Boolean
    $services_build: Boolean
    $services_ff: Boolean
    $services_qs: Boolean
    $services_design: Boolean
    $scope_of_works: String
    $projectUsers: [NewProjectUser]
    $projectContacts: [NewProjectContact]
  ) {
    addProject(
      companyId: $companyId
      companies_name: $companies_name
      project_name: $project_name
      address_1: $address_1
      address_2: $address_2
      city: $city
      postcode: $postcode
      scope_of_works: $scope_of_works
      services_hs: $services_hs
      services_build: $services_build
      services_ff: $services_ff
      services_qs: $services_qs
      services_design: $services_design
      projectUsers: $projectUsers
      projectContacts: $projectContacts
    ) {
      _id
      project_id
      job_no
    }
  }
`;

export default function Confirmation() {
  const history = useHistory();

  const [addProject, { loading, error, data }] = useMutation(ADD_PROJECT, {
    onCompleted: (data) => {
      openSnackbar({
        message: "Project has been created.",
        type: "success",
      });
      // console.log("return data", data.addProject.job_no);
      history.push(`./projects/single/${data.addProject.project_id}`);

      // next();

      closeDialog();
    },
    onError: (error) => {
      console.log(error);
      openSnackbar({
        message: "Failed to create project.",
        type: "error",
      });
    },
  });

  // setJobNo
  // useEffect(() => {
  //   if (data?.addProject?.job_no) {
  //     job_noSet(data.addProject.job_no);
  //   }
  // }, [data?.addProject?.job_no, job_noSet]);

  return (
    <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
      {({
        values,
        pristine,
        submitting,
        form: {
          mutators: { setJobNo },
        },
      }) => (
        <Box
          //main container
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            //content container
            container
            spacing={3}
            style={{ flex: "1 1 auto" }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  marginBottom: "24px",
                  fontFamily: "Poppins",
                }}
              >
                Confirmation
              </Typography>

              <Typography>Client</Typography>

              <Typography variant="body1" style={{ color: labelColour }}>
                {values.companies_name}
              </Typography>

              <Divider
                style={{
                  backgroundColor: "#C3CAD8",
                  marginTop: "20px",
                  marginBottom: "6px",
                }}
              />

              <Typography>Name and Location</Typography>

              <Grid item md={12} style={{ marginBottom: "15px" }}>
                <Typography
                  variant="body1"
                  style={{ color: labelColour, wordWrap: "break-word" }}
                >
                  {values.project_name}
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{ color: labelColour }}>
                  {values.project_address.address_1}
                </Typography>
                <Typography variant="body1" style={{ color: labelColour }}>
                  {values.project_address.address_2}
                </Typography>
                <Typography variant="body1" style={{ color: labelColour }}>
                  {values.project_address.city}
                </Typography>
                <Typography variant="body1" style={{ color: labelColour }}>
                  {values.project_address.postcode}
                </Typography>
              </Grid>

              <Divider
                style={{
                  backgroundColor: "#C3CAD8",
                  marginTop: "20px",
                  marginBottom: "6px",
                }}
              />
              <Typography>Scope of Works</Typography>

              <Grid item md={6} style={{ marginBottom: "15px" }}>
                {values.services_design && (
                  <Typography variant="body1" style={{ color: labelColour }}>
                    Design
                  </Typography>
                )}
                {values.services_qs && (
                  <Typography variant="body1" style={{ color: labelColour }}>
                    QS
                  </Typography>
                )}
                {values.services_ff && (
                  <Typography variant="body1" style={{ color: labelColour }}>
                    {"F & F"}
                  </Typography>
                )}
                {values.services_build && (
                  <Typography variant="body1" style={{ color: labelColour }}>
                    Build
                  </Typography>
                )}
                {values.services_hs && (
                  <Typography variant="body1" style={{ color: labelColour }}>
                    {"Health & Safety"}
                  </Typography>
                )}
              </Grid>

              <Grid item md={12}>
                <Typography
                  variant="body1"
                  style={{
                    color: labelColour,
                    wordWrap: "break-word",
                  }}
                >
                  {values.scope_of_works}
                </Typography>
              </Grid>

              <Divider
                style={{
                  backgroundColor: "#C3CAD8",
                  marginTop: "20px",
                  marginBottom: "6px",
                }}
              />

              <Typography>Internal Contacts</Typography>

              <Grid item md={12}>
                {values.projectUsers?.map((user) => (
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography
                        variant="body1"
                        style={{ color: labelColour }}
                      >
                        {user.first_name + " " + user.last_name}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        style={{ color: labelColour }}
                      >
                        {user.role}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      {user.lead ? (
                        <Typography
                          variant="body1"
                          style={{ color: labelColour }}
                        >
                          Lead
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          style={{ color: labelColour }}
                        ></Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Divider
                style={{
                  backgroundColor: "#C3CAD8",
                  marginTop: "20px",
                  marginBottom: "6px",
                }}
              />

              <Typography>Client Contacts</Typography>

              <Grid container>
                {values.projectContacts?.map((contact) => (
                  <Grid
                    item
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body1" style={{ color: labelColour }}>
                      {contact.contact_name}
                    </Typography>
                    <Typography variant="body1" style={{ color: labelColour }}>
                      {contact.contact_position}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid container style={{ marginTop: "calc(5% + 50px)" }}>
            <Grid item xs={12}>
              <Divider
                style={{
                  // marginTop: "25px",
                  marginBottom: "12px",
                  marginLeft: "-12px",
                  marginRight: "-24px",
                  backgroundColor: "#C3CAD8",
                }}
              />
            </Grid>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "13px",
                marginBottom: "12px",
                marginLeft: "24px",
                marginRight: "24px",
              }}
            >
              <Button
                // disabled={true}
                onClick={previous}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  addProject({
                    variables: {
                      companyId: values.companyId,
                      companies_name: values.companies_name,
                      project_name: values.project_name,
                      address_1: values.project_address.address_1,
                      address_2: values.project_address.address_2,
                      city: values.project_address.city,
                      postcode: values.project_address.postcode,
                      services_hs: values.services_hs,
                      services_build: values.services_build,
                      services_ff: values.services_ff,
                      services_qs: values.services_qs,
                      services_design: values.services_design,
                      scope_of_works: values.scope_of_works,

                      projectUsers: values.projectUsers?.map((user) => {
                        return {
                          _id: user._id,
                          role: user.role,
                          lead: user.lead,
                        };
                      }),

                      projectContacts: values.projectContacts?.map(
                        (contact) => {
                          return {
                            _id: contact._id,
                            role: contact.contact_role,
                          };
                        }
                      ),
                    },
                  });
                }}
                disabled={submitting}
                // type="submit"
              >
                Create Project
              </Button>
            </Grid>
          </Grid> */}

          <PageSwitch
            disabled={submitting}
            nextButtonLabel="Create Project"
            onClick={() => {
              addProject({
                variables: {
                  companyId: values.companyId,
                  companies_name: values.companies_name,
                  project_name: values.project_name,
                  address_1: values.project_address.address_1,
                  address_2: values.project_address.address_2,
                  city: values.project_address.city,
                  postcode: values.project_address.postcode,
                  services_hs: values.services_hs,
                  services_build: values.services_build,
                  services_ff: values.services_ff,
                  services_qs: values.services_qs,
                  services_design: values.services_design,
                  scope_of_works: values.scope_of_works,

                  projectUsers: values.projectUsers?.map((user) => {
                    return {
                      _id: user._id,
                      role: user.role,
                      lead: user.lead,
                    };
                  }),

                  projectContacts: values.projectContacts?.map((contact) => {
                    return {
                      _id: contact._id,
                      role: contact.contact_role,
                    };
                  }),
                },
              });
            }}
          />
        </Box>
      )}
    </FormSpy>
  );
}
