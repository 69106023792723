import React, { useRef, useState } from "react";
import useSessionStorage from "../../../reusable/useSessionStorage";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import { ViewSwitcher } from "./viewSwitcher";
import { getStartEndDateForProject } from "./helper";
import { Gantt, ViewMode } from "@georgywebdev/gantt-task-react";

import {
  addMonths,
  differenceInDays,
  format,
  isDate,
  max,
  min,
} from "date-fns";

import { labelColour } from "../../../queries/Project";

import { Button } from "@material-ui/core";
import Typography from "../../../uicomponents/Typography";
import StraightIcon from "@mui/icons-material/Straight";

import { primaryColor, secondaryColor } from "../../../assets/ThemeStyling";
import "./styles.css";

const newDate = new Date();
const oneMonthAgo = addMonths(newDate, -1);

export default function GanttChart({ data, refetch }) {
  // eslint-disable-next-line no-unused-vars
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState();

  const chartLengthInDays = useRef();

  const [value, setValue] = useSessionStorage("sortOrder", {
    sortDesc: false,
    sortKey: "",
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    pageStyle: "margin: 0",
  });

  // console.log("value", value);

  // console.log("ref", componentRef?.current?.offsetWidth);
  const tableCellWidth = 85;
  // window.innerWidth * 0.25 >= 75 ? 75 : window.innerWidth * 0.25;
  const chartCellWidth =
    window.innerWidth * 0.02 >= 20 ? 20 : window.innerWidth * 0.02;

  // console.log(
  //   "params",
  //   tableCellWidth,
  //   chartCellWidth,
  //   chartLengthInDays.current
  // );

  // console.log(
  //   "width",
  //   componentRef?.current?.clientWidth,
  //   11 * tableCellWidth + chartCellWidth * chartLengthInDays.current
  // );
  // console.log(
  //   "scale",
  //   componentRef?.current?.clientWidth /
  //     (11 * tableCellWidth + chartCellWidth * chartLengthInDays.current)
  // );
  // console.log("chartLengthInDays", chartLengthInDays.current);

  const handleTaskChange = (task) => {
    // console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t,
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On progress change Id:" + task.id);
  };

  const handleDoubleClick = (task) => {
    // console.log(task);
  };

  const handleSelect = (task, isSelected) => {
    // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On expander click Id:" + task.id);
  };

  let reformattedTasks = [
    {
      start: oneMonthAgo,
      end: newDate,
      progress: 0,
      type: "project",
      hideChildren: false,

      id: "_id",
      name: "placeholder",
      tableDisplayData: ["", "", ""],
    },
  ];

  //formatting data
  if (data?.projectsForGanttChart && data?.projectsForGanttChart[0]) {
    //get the max of project end dates
    let arrayOfProjectEndDates = [];

    data?.projectsForGanttChart.forEach((element) => {
      if (element.project.est_handover_date) {
        arrayOfProjectEndDates.push(
          new Date(element.project.est_handover_date),
        );
      }
      if (element.project.start_on_site) {
        arrayOfProjectEndDates.push(new Date(element.project.start_on_site));
      }
    });
    //or make a fake one if there are none
    if (!arrayOfProjectEndDates.find((element) => isDate(element)))
      arrayOfProjectEndDates = [addMonths(new Date(), 4)];
    const maxEndDate = max(arrayOfProjectEndDates);

    //get the earliest of project start dates
    let arrayOfProjectStartDates = [];

    data?.projectsForGanttChart.forEach((element) => {
      if (element.project.start_on_site) {
        arrayOfProjectStartDates.push(new Date(element.project.start_on_site));
      }
    });
    const minEndDate = min(arrayOfProjectStartDates);
    chartLengthInDays.current = differenceInDays(maxEndDate, minEndDate) + 23;
    // console.log("diff", chartLengthInDays.current);

    //formatting data
    reformattedTasks = data?.projectsForGanttChart.map((element) => {
      const { project, projectUsers } = element;
      var start = new Date(project.start_on_site);
      var end = project.est_handover_date
        ? new Date(project.est_handover_date)
        : maxEndDate;
      if (start != null && end != null && start.getTime() > end.getTime()) {
        end = addMonths(start, 4);
      }
      return {
        start: start,
        end: end,
        progress: 0,
        type: "project",
        hideChildren: false,

        id: project._id,
        name: project.project_name,
        tableDisplayData: [
          <Link
            style={{ color: secondaryColor }}
            to={"/projects/single/" + project.project_id}
          >
            {project.job_no}
          </Link>,
          project.project_name,
          project.companies_name,
          project.city,

          projectUsers.design?.initials ? projectUsers.design.initials : "",
          projectUsers.qs?.initials ? projectUsers.qs.initials : "",
          projectUsers.pm?.initials ? projectUsers.pm.initials : "",

          element.duration,

          format(new Date(project.start_on_site), "eee dd/MM/yyyy"),
          project.est_handover_date
            ? format(new Date(project.est_handover_date), "eee dd/MM/yyyy")
            : "",

          project.project_status_text,
        ],
      };
    });
  }

  const MiniTypography = ({ text, sortKey }) => {
    return (
      <Typography
        variant="body2"
        style={{ fontSize: "14px", marginTop: "-10px", marginLeft: "4px" }}
        onClick={() => {
          setValue({
            sortDesc: value.sortKey === sortKey ? !value.sortDesc : false,
            sortKey: sortKey,
          });
          refetch({
            sortBy: sortKey,
            sortDesc: value.sortKey === sortKey ? value.sortDesc : true,
          });
        }}
      >
        {text}
        {sortKey === value.sortKey && (
          <StraightIcon
            style={{
              transform: `rotate(${value.sortDesc ? 0 : 180}deg)`,
              fill: labelColour,
              scale: "0.8",
              margin: "-6px",

              position: "relative",
              top: " 2px",
            }}
          />
        )}
      </Typography>
    );
  };

  let display = (
    <Gantt
      tasks={reformattedTasks}
      viewMode={"Day"}
      headersList={[
        <MiniTypography text="Job No" sortKey="jobNumber" />,
        <MiniTypography text="Job Name" sortKey="jobName" />,
        <MiniTypography text="Client" sortKey="client" />,
        <MiniTypography text="Location" sortKey="location" />,
        <MiniTypography text="Designer" sortKey="designer" />,
        <MiniTypography text="QS" sortKey="qs" />,
        <MiniTypography text="PM" sortKey="pm" />,
        <MiniTypography text="Duration" sortKey="duration" />,
        <MiniTypography text="Start" sortKey="start" />,
        <MiniTypography text="Finish" sortKey="finish" />,
        <MiniTypography text="Status" sortKey="status" />,
      ]}
      onDateChange={handleTaskChange}
      onDelete={handleTaskDelete}
      onProgressChange={handleProgressChange}
      onDoubleClick={handleDoubleClick}
      onSelect={handleSelect}
      onExpanderClick={handleExpanderClick}
      listCellWidth={tableCellWidth}
      columnWidth={chartCellWidth}
    />
  );

  return (
    <>
      <div style={{ width: "100%", marginBottom: "1rem" }}>
        <Button
          onClick={handlePrint}
          style={{
            color: "#fff",
            backgroundColor: primaryColor,
            marginLeft: "auto",
            display: "block",
          }}
        >
          Print
        </Button>
      </div>
      <div
        ref={componentRef}
        style={{ overflow: "auto" }}
        className={"chartContainer"}
      >
        <style>{`@media print {.chartContainer{zoom: ${
          componentRef?.current?.scrollWidth /
          (11 * tableCellWidth + chartCellWidth * chartLengthInDays.current + 1)
        }}}`}</style>

        {display}
      </div>
    </>
  );
}
