import React from "react";
import { Router } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";
import { ApolloProvider as ApolloProvider2 } from "@apollo/react-components";
import Cookies from "js-cookie";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "@web-applications/daffodil-component-library";
import DaffodilTheme from "./assets/DaffodilTheme.json";
import muiTheme from "./assets/ThemeStyling";
import Notifier from "./reusable/Notifier";
import HomeRoutes from "./routes/HomeRoutes";
import LoginRoutes from "./routes/LoginRoutes";
import UserRoutes from "./routes/UserRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import CustomerRoutes from "./routes/CustomerRoutes";
import Popup from "./reusable/Popup";
import ScrollablePopup from "./reusable/ScrollablePopup";
import ContactRoutes from "./routes/ContactRoutes ";
import ProjectRoutes from "./routes/ProjectRoutes";
import { ProjectStatusProvider } from "./context/ProjectStatusContext";

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      const unsecureRoutes = [
        "/login",
        "/reset-password",
        "/forgotten-password",
      ];
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (
            (error.extensions.code === "UNAUTHENTICATED" ||
              error.extensions.code === "INTERNAL_SERVER_ERROR") &&
            error.message.indexOf("userId") > -1
          ) {
            Cookies.remove("app");
            let doRedirect = true;
            unsecureRoutes.forEach((route) => {
              if (window.location.pathname.indexOf(route) !== -1) {
                doRedirect = false;
              }
            });
            if (doRedirect) {
              window.location.href =
                "/login?returnTo=" + window.location.pathname;
            }
          }

          console.error(`[GraphQl error]: ${error.message}`);
        });
      }
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    createUploadLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
});

const history = createBrowserHistory();

export default function App() {
  return (
    <HelmetProvider>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={DaffodilTheme}>
          <ApolloProvider2 client={client}>
            <ApolloProvider client={client}>
              <ProjectStatusProvider>
                <Router history={history}>
                  {[
                    HomeRoutes,
                    LoginRoutes,
                    UserRoutes,
                    AdminRoutes,
                    CustomerRoutes,
                    ContactRoutes,
                    ProjectRoutes,
                  ]}
                  <Notifier />
                  <Popup />
                  <ScrollablePopup />
                </Router>
              </ProjectStatusProvider>
            </ApolloProvider>
          </ApolloProvider2>
        </ThemeProvider>
      </MuiThemeProvider>
    </HelmetProvider>
  );
}
