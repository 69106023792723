import React from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Field, Form } from "react-final-form";
import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { ADD_COMPANY } from "../../queries/Customer";
import { useMutation } from "@apollo/client";
import { closeDialog } from "../../reusable/ScrollablePopup";
import { openSnackbar } from "../../reusable/Notifier";
import { clientNameLabel, statusNameLabel } from "../../assets/GlobalLabels";
import { ADD_PROJECT_STATUS } from "../../queries/ProjectStatus";

const NewProjectStatusModal = ({ onAdded }) => {
  const [addProjectStatus, { error }] = useMutation(ADD_PROJECT_STATUS, {
    onCompleted: () => {
      openSnackbar({
        message: "Project status has been added.",
        type: "success",
      });
      onAdded();
      closeDialog();
    },
    onError: (error) => {
      console.error(error);
      openSnackbar({
        message: "Failed to add the project status.",
        type: "error",
      });
    },
  });

  const onSubmit = (values) => {
    const { statusName, archive } = values;

    console.log(values);
    addProjectStatus({
      variables: {
        name: statusName,
        archive: archive || false,
        active: "Active",
      },
    });
  };

  const renderForm = ({ handleSubmit, submitting, values }) => (
    <form onSubmit={handleSubmit}>
      <>
        <Grid container spacing={3} justify="center" style={{ padding: "2em" }}>
          <div style={{ width: "100%", textAlign: "left" }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                textAlign: "left",
                paddingLeft: "0.5em",
              }}
            >
              New Project Status
            </Typography>
          </div>
          <Grid item xs={12}>
            <Field name="statusName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  // inputRef={(input) => input && input.focus()}
                  label={statusNameLabel}
                  fullWidth
                  required={true}
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="archive" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "archive" }}
                      {...input}
                      value="archive"
                    />
                  }
                  label="Should projects with this status have their files archived automatically?"
                />
              )}
            </Field>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Divider
              style={{
                // marginTop: "25px",
                marginBottom: "12px",
                marginLeft: "-12px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>
          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "13px",
              marginBottom: "12px",
              paddingLeft: "0.75em",
              paddingRight: "0.75em",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                submitting ||
                values.statusName == null ||
                values.statusName.length === 0
              }
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </>
    </form>
  );

  return (
    <Card
      style={{
        padding: "0",
        border: "none",
        boxShadow: "none",
        borderRadius: "8px",
      }}
    >
      <Form initialValues={{}} onSubmit={onSubmit} render={renderForm} />
    </Card>
  );
};

export default NewProjectStatusModal;
